import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

const Nav: React.FC = () => {
  const [show, setShow] = useState(false);

  const toggle = () => setShow(!show);
  return (
    <nav className="navbar navbar-fixed-top nav_dark landing_nav px-4 py-3">
      <Link to="/" className="navbar-brand">
        <img
          src="https://flangoo-cdn.s3.us-east-2.amazonaws.com/tdtiktakis/assets/logo.png"
          alt="nav logo"
        />
      </Link>
      <div>
        <div className="btn-group login_ctas">
          <Link to="/student_signin" className="btn btn-primary">
            {" "}
            Student Sign-In{" "}
          </Link>
          <button
            type="button"
            onMouseEnter={isMobile ? undefined : toggle}
            onClick={toggle}
            className="btn btn-primary dropdown-toggle dropdown-toggle-split no_focus"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle Dropdown</span>
          </button>
          <div
            onMouseLeave={isMobile ? undefined : toggle}
            className={`dropdown-menu dropdown-menu-right bg-light ${
              show ? "show" : ""
            } `}
          >
            <Link to="/signin" className="dropdown-item" onClick={toggle}>
              {" "}
              Teacher Sign-In
            </Link>
            <div className="dropdown-divider"></div>
            <Link className="dropdown-item" to="/signup" onClick={toggle}>
              Create Teacher Account
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
