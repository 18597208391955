import { gql } from "@apollo/client";

export const GET_VIDEOS = gql`
  query getVideos($lang_id: ID!, $class_id: ID!) {
    videos(
      lang_id: $lang_id
      class_id: $class_id
      published_only: true
      order: "level_id"
    ) {
      id
      title
      enabled
      chapters
      url
      total_ratings
      coming_soon
      vocab_url
      video_url
      level_id
      rank
      rating
      rating_teachers
      rating_students
      image_ver
      has_prereading
      unique_words
      total_words
      tense
      images {
        cover
        thumb
        poster
      }
      level {
        id
        name
      }
      description_short
      release_date
      language {
        id
        name
      }
      categories {
        id
        name
        buy_text
      }
      nav_colors {
        background
        color
      }
    }
  }
`;

export const GET_CLASSES = gql`
  query {
    classes {
      value: id
      txt: name
      students
      created
    }
  }
`;

export const DISABLE_BOOKS = gql`
  mutation disableBooks($books_ids: [ID!]!, $class_id: ID!, $enable: Boolean!) {
    disableBooks(books_id: $books_ids, class_id: $class_id, enable: $enable)
  }
`;

export const TOGGLE_GLOSSARY = gql`
  mutation glssaryToggle(
    $show_less: Boolean!
    $books_ids: [ID]!
    $class_id: ID!
  ) {
    glssaryToggle(
      show_less: $show_less
      class_id: $class_id
      books_id: $books_ids
    )
  }
`;
