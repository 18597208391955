import React, { MouseEvent } from "react";
import { PlanType } from "../../../models/plans";

type PlansProps = {
  plans: PlanType[];
  selectedPlan?: PlanType;
  handlePlanSelect?: (ev: MouseEvent<HTMLButtonElement>) => void;
  isBuyUrl?: boolean;
  is_loyalty?: boolean;
};

const Plans: React.FC<PlansProps> = ({
  plans,
  selectedPlan,
  handlePlanSelect,
  isBuyUrl,
  is_loyalty = false,
}) => {
  let subtitle_txt = "Purchase any of the membership packages below.";

  if (is_loyalty) {
    subtitle_txt = "Loyalty membership packages";
  }
  return (
    <div className={`${isBuyUrl ? "col-lg-12" : "col-lg-10"}`}>
      <h4 className="black_text">
        {handlePlanSelect ? subtitle_txt : "TikTakis Plans"}
      </h4>
      {handlePlanSelect ? (
        <ul className="black_text">
          <li>Select your preferred membership package and make payment.</li>
          <li>You can cancel your subscription at any time.</li>
          <li>
            You can also subscribe using a purchase order on the Teacher's
            Discovery website by{" "}
            <a
              href="https://www.teachersdiscovery.com/product/29270"
              target="_blank"
              rel="noreferrer"
            >
              <u>clicking here</u>
            </a>
            {is_loyalty ? (
              <>
                {" "}
                <span>
                  and using promo code <b>LOYALTY24</b> at checkout
                </span>
              </>
            ) : (
              ""
            )}
            .
          </li>
          {isBuyUrl ? (
            ""
          ) : (
            <li>
              Renewing will ADD ON to the end of your current expiration date.
            </li>
          )}
        </ul>
      ) : null}
      <table className="table table-striped table-hover">
        <tbody>
          <tr role="rowheader">
            <td>
              <h6>Packages</h6>
            </td>
            {plans.length > 0
              ? plans.map((plan) => {
                  const compare_price =
                    is_loyalty && plan.loyalty_price && !plan.has_promo
                      ? plan.price_amount
                      : plan.regular_price;
                  const save =
                    is_loyalty && plan.loyalty_price
                      ? compare_price - plan.loyalty_price
                      : plan.regular_price
                      ? compare_price - plan.price_amount
                      : 0;
                  return (
                    <td key={`plan.name${plan.id}`} className={`text-center `}>
                      <h5>{plan.name}</h5>
                      {(is_loyalty || plan.has_promo) && save > 1
                        ? `Save $${save.toFixed(2)}`
                        : ""}
                    </td>
                  );
                })
              : null}
          </tr>
          <tr>
            <td>Subscription Price</td>
            {plans.length > 0
              ? plans.map((plan) => (
                  <td
                    key={`plan.price_amount${plan.id}`}
                    className={`text-center `}
                  >
                    {plan.loyalty_price ? (
                      <span className="text-danger">
                        USD
                        <b> ${plan.loyalty_price}</b>
                      </span>
                    ) : (
                      <span className="text-danger">
                        USD ${plan.price_amount.toLocaleString()}
                      </span>
                    )}
                    {plan.loyalty_price ? (
                      <>
                        <br />
                        <small className="badge text-danger">
                          <i className="far fa-star" aria-hidden="true"></i>{" "}
                          Loyalty Pricing
                        </small>
                      </>
                    ) : null}
                    <br />
                    {is_loyalty &&
                    (plan.regular_price || plan.price_amount) >
                      (plan.loyalty_price || plan.price_amount) ? (
                      <span className="text-muted">
                        <del>${plan.regular_price || plan.price_amount}</del>
                      </span>
                    ) : null}
                    {!is_loyalty && plan.regular_price > plan.price_amount ? (
                      <span className="text-muted">
                        <del>${plan.regular_price || plan.price_amount}</del>
                      </span>
                    ) : null}
                  </td>
                ))
              : null}
          </tr>
          <tr>
            <td>Students That Can Access As Well</td>
            {plans.length > 0
              ? plans.map((plan) => (
                  <td
                    key={`plan.students${plan.id}`}
                    className={`text-center `}
                  >
                    {plan.students}
                  </td>
                ))
              : null}
          </tr>
          <tr>
            <td>
              Access on Your Computer, TV, <br /> Phone, and Tablet
            </td>
            {plans.length > 0
              ? plans.map((plan) => (
                  <td className={`text-center `} key={`access_on${plan.id}`}>
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </td>
                ))
              : null}
          </tr>
          <tr>
            <td>Spanish, French, and German Titles</td>
            {plans.length > 0
              ? plans.map((plan) => (
                  <td className={`text-center `} key={`languages${plan.id}`}>
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </td>
                ))
              : null}
          </tr>
          <tr>
            <td>Cancel Anytime</td>
            {plans.length > 0
              ? plans.map((plan) => (
                  <td className={`text-center `} key={`cancel${plan.id}`}>
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </td>
                ))
              : null}
          </tr>
          {handlePlanSelect ? (
            <tr>
              <td>
                <span>Select Your Plan:</span>
              </td>
              {plans.length > 0
                ? plans.map((plan) => (
                    <td className={`text-center `} key={`button${plan.id}`}>
                      <button
                        className={`btn  btn-block py-3 ${
                          selectedPlan && selectedPlan.id === plan.id
                            ? "btn-success"
                            : "btn-outline-dark"
                        }`}
                        onClick={handlePlanSelect}
                        data-plan-id={plan.id}
                      >
                        {selectedPlan && selectedPlan.id === plan.id
                          ? "Selected"
                          : "Select"}
                      </button>
                    </td>
                  ))
                : null}
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default Plans;
