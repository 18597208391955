import React from "react";
import { VideoItem } from "../../../models/video";

type VideoDetailsProps = {
  video: VideoItem;
};

const VideoDetails: React.FC<VideoDetailsProps> = ({ video }) => (
  <div className="video-details">
    <h1>{video.title}</h1>
    <VideoStats
      rating={video.rating}
      total_ratings={video.total_ratings}
      rank={video.rank}
      view_time={video.view_time || 5}
    />
    <div className="last-updated">
      <span>
        <i className="fal fa-calendar mr-1"></i> Last updated{" "}
        {video.release_date}
      </span>
    </div>
    <div className="language">
      <span>
        <i className="fal fa-globe mr-1"></i> {video.language.name}
      </span>
    </div>
  </div>
);

type VideoStatsProps = {
  rating: number;
  total_ratings: number;
  rank: number;
  view_time: number;
};
const VideoStats: React.FC<VideoStatsProps> = ({
  rating,
  total_ratings,
  rank,
  view_time,
}) => (
  <div className="video-stats">
    <div className="item">
      <span className="item_value">
        {rating.toFixed(1)}
        <i className="ml-1 fal fa-star"></i>
      </span>
      <span className="item_text">{total_ratings} ratings</span>
    </div>
    <div className="item">
      <span className="item_value">{rank}</span>
      <span className="item_text">Students</span>
    </div>
    <div className="item">
      <span className="item_value">{view_time || "5"} hours</span>
      <span className="item_text">Total</span>
    </div>
  </div>
);

export default VideoDetails;
