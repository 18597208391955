import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ClassItem } from "../../../models/classes";
import { UserItem } from "../../../models/user";
import { CopyButton } from "../../common/buttons";
import { GET_CLASSES } from "./queries";
import { PaperSheet } from "../../common/ui_elements";

const HowStudentSignup: React.FC = () => {
  const user: UserItem = JSON.parse(localStorage.getItem("user") || "{}");
  const [class_id, setClassId] = useState("");
  const [signupUrl, setSignupUrl] = useState(
    `${process.env.REACT_APP_URL}/studentsignup/${user.id}`
  );
  const { data: classes_db } = useQuery<{ classes: ClassItem[] }>(GET_CLASSES, {
    fetchPolicy: "no-cache",
  });

  const handleType = (ev: { target: HTMLSelectElement }): void => {
    const value = ev.target.value;
    setClassId(value);
    const set_url = `${process.env.REACT_APP_URL}/studentsignup/${user.id}${
      value !== "0" ? `/${value}` : ""
    }`;
    setSignupUrl(set_url);
  };

  return (
    <main className="first-container last-container py-5">
      <div className="container">
        <h3>How to Have Your Students Sign Up</h3>
        <br />
        <h3>Add Students to class</h3>
        <div className="row">
          <div className="col-md-4">
            {classes_db && classes_db.classes ? (
              <div className={`form-group `}>
                <label className="green_dark">
                  {" "}
                  Select if you want to add students to a class
                </label>
                <select
                  className="form-control"
                  data-type="class_id"
                  id="class_id"
                  onChange={handleType}
                  value={class_id}
                >
                  <option value={0}>Let Students Decide</option>
                  {classes_db.classes.map((item, key) => (
                    <option
                      key={`classes_db.classes_${item.value}`}
                      value={item.value}
                    >
                      Add to: {item.txt}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
          </div>
          <div className="col-md-10">
            <p>
              When students sign up, they will be given the option to sign up
              with Google OR with their full name, their class period, and a new
              password.{" "}
              <b>
                Be sure to let them know if you would like them to choose one
                option or the other
              </b>
              . Once they sign up, they will immediately be added to your
              student panel and given a login code. <br />
              <b className="text-danger">NOTE:</b> When you send them the link
              below in either OPTION 1 or OPTION 2, make sure you remind them to
              save their login code and password for future use. We highly
              recommend using verbiage similar to the text below.
            </p>
          </div>
        </div>
        <h4>
          OPTION 1: Post the link to your Learning Management System (LMS)
        </h4>
        <p>
          <CopyButton classes="green_dark" text={signupUrl} showimg={true}>
            <span id="signupUrl">{signupUrl}</span>
          </CopyButton>
        </p>
        <PaperSheet copy={true}>
          {" "}
          Good day students, I have recently purchased a subscription to
          TikTakis.com, a Netflix-type site of digital readers.
          <br />
          <br />
          Please click the link below and register your name and class period.{" "}
          <br />
          NOTE: You must write down your login code and password so that you do
          not forget it! <br />
          <span id="signupUrl">{signupUrl}</span> <br />
          After you register, I will let you know what readers we will start
          reading soon. Thank you.
        </PaperSheet>

        <h4>OPTION 2: Email your students</h4>
        <p>Copy the text below to email to your students.</p>
        <PaperSheet copy={true}>
          Good day students, <br />
          I have recently purchased a subscription to TDTikTakis.com, a
          Netflix-type site of digital readers. <br /> <br />
          Please click the link below and register your name and class period.{" "}
          <br />
          NOTE: You must write down your login code and password <br />
          so that you do not forget it! <br />
          {signupUrl} <br />
          After you register, I will let you know what readers we will start
          reading soon. Thank you.
        </PaperSheet>

        <Link to="/students" className="btn btn-link mb-4">
          <i className="fas fa-chevron-left" aria-hidden="true"></i> Back to
          Student List{" "}
        </Link>
      </div>
    </main>
  );
};

export default HowStudentSignup;
