import React, { useEffect, useState } from "react";
import { UserSettings } from "../../../models/user";
import { CheckBox } from "../../common/inputs";
import { useMutation } from "@apollo/client";
import { UPDATEPASSWORDS } from "./queries";

type StudentsProps = {
  settings: UserSettings;
  setSettings: any;
};
const Students: React.FC<StudentsProps> = ({ settings, setSettings }) => {
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [setNew, { loading }] = useMutation(UPDATEPASSWORDS);

  useEffect(
    () => {
      if (newPassword === "Saved!") {
        setTimeout(() => {
          setNewPassword(null);
        }, 2000);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newPassword]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const setting = e.target.id as keyof UserSettings;
    let value = e.target.checked;
    const reverted = e.target.getAttribute("data-reverted");
    if (reverted) {
      value = !value;
    }
    setSettings(setting, value);
  };
  const handleType = (ev: { currentTarget: HTMLInputElement }) => {
    const value = ev.currentTarget.value;
    setNewPassword(value);
  };

  const handleUpdate = () => {
    if (!newPassword || newPassword.length < 6) {
      return false;
    }
    setNew({ variables: { password: newPassword } }).then((res) => {
      setNewPassword("Saved!");
    });
  };

  return (
    <div className="setting_panel">
      <h4>Students Preferences</h4>
      <div className="row">
        <div className="col-md-7">
          <CheckBox
            handleChange={handleChange}
            label="Show achievements to students"
            id="hide_achievements"
            reverted={true}
            checked={settings.hide_achievements}
            classes="mb-3"
            help="If you disable this option, students will no longer have access to view their own or their classmates' achievements, but their progress will still be tracked."
          />
          <CheckBox
            handleChange={handleChange}
            label="Allow students to change their own passwords"
            id="block_password_change"
            checked={settings.block_password_change}
            reverted
            classes="mb-3"
            help="If you disable this option, students will be unable to change their passwords. The password they entered for the first time or the one set up by you will be the only one used."
          />
          <CheckBox
            handleChange={handleChange}
            label="Show language selection on Library page"
            id="block_lang_selection"
            checked={settings.block_lang_selection}
            reverted
            classes="mb-3"
            help="If you disable this option, students will no longer have the language selection available on the Library page. However, they will still be able to modify their preferred language on their individual student page."
          />
          <div className="danger-zone py-3">
            <h5>Danger Zone</h5>
            <h6>Reset all students' passwords</h6>
            <small className="form-text opacity_8">
              Update <b>all</b> of your students' passwords with a single click.
            </small>
            <div className="input-group mt-2">
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Type new password"
                onChange={handleType}
                value={newPassword === "Saved!" ? "" : newPassword || ""}
              />
              <div className="input-group-append">
                <button
                  className={`btn ${
                    newPassword
                      ? newPassword === "Saved!"
                        ? "btn-success"
                        : "btn-danger"
                      : "btn-outline-danger"
                  }
                  `}
                  type="button"
                  id="button-addon2"
                  onClick={handleUpdate}
                >
                  {loading ? (
                    <i
                      className="fal fa-spinner-third fa-spin "
                      aria-hidden="true"
                    ></i>
                  ) : newPassword === "Saved!" ? (
                    <>
                      Saved!{" "}
                      <i className="fal fa-check " aria-hidden="true"></i>
                    </>
                  ) : (
                    "Update all passwords"
                  )}
                </button>
              </div>
              <small className="form-text opacity_8">
                <span className="text-success">Tip:</span> Setting the new
                password to "changeme" will prompt students to create their own
                password when they log in.
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Students;
