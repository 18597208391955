import React, { useEffect, useState, useContext } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { PlanItem, UserItem } from "../../../models/user";
import SignupForm from "./signupForm";
import { PlanType } from "../../../models/plans";
import { PageSettingItem } from "../../../models/pageSettings";
import { LoaderDots } from "../../../middleware/main_loader";
import { GET_PLANS } from "../purchaseplan/queries";
import OrderSummary from "./orderSummary";
import PaymentSection from "./payment";
import { DataContext } from "../../../context/data-context";
import { useQueryParams } from "../../../middleware/common-functions";
import { GETBOOOK } from "./queries";
import { ReaderItem } from "../../../models/reader";

type SectionsType = false | "signup" | "payment" | "giveaway";

const TeacherSignup: React.FC = () => {
  const location = useLocation();
  const dataContext = useContext(DataContext);
  const [pageType, setPageType] = useState<SectionsType>(false);
  const params = useQueryParams(window.location);
  const has_book = params.get("b");
  const [selectedPlan, setSelectedPlan] = useState<undefined | PlanType>(
    undefined
  );
  const [section, setSection] = useState<SectionsType>("signup");

  const { plan_id } = useParams<{
    plan_id?: string;
  }>();
  const [fetchPlans, { data: dataPlans, called: fetchedPlans }] = useLazyQuery<{
    plans: PlanType[];
    pageSettings: PageSettingItem[];
  }>(GET_PLANS, { fetchPolicy: "no-cache" });
  const {
    data: dataBooks,
    loading: gettingBook,
    error: errorOnBook,
  } = useQuery<{
    book?: ReaderItem;
  }>(GETBOOOK, {
    fetchPolicy: "no-cache",
    skip: !has_book,
    variables: { book_title: has_book },
  });
  const plans_data: PlanItem[] = JSON.parse(
    localStorage.getItem("plans_data") || "{}"
  );
  const trial = plans_data.find((plan) => plan.id === "2");
  let title = `Sign up for a free ${trial?.days}-day trial of our online ereader library`;
  let sub_title = "No credit card required.";
  if (pageType === "payment") {
    title = "Create Your Account";
    sub_title = "";
  } else if (pageType === "giveaway") {
    title = `Sign up for a free trial through June 30 of our online ereader library`;
    sub_title = "";
  }
  useEffect(() => {
    if (errorOnBook) {
      console.log("index.tsx:59 | errorOnBook", errorOnBook);
    }
  }, [errorOnBook]);

  useEffect(() => {
    const document_body = document.getElementsByTagName("body")[0];
    const currentPath = location.pathname;
    let page_type: SectionsType = "signup";
    if (currentPath.includes("payment")) {
      page_type = "payment";
    } else if (currentPath.includes("create_account")) {
      page_type = "giveaway";
    }
    if (document_body) {
      document_body.classList.add("white_bg");
    }
    setPageType(page_type);
    if (page_type === "payment" && fetchedPlans === false) {
      fetchPlans();
    }
    return () => {
      document_body.classList.remove("white_bg");
    };
    //eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (dataPlans?.plans && plan_id) {
      const selectedPlanset = dataPlans?.plans.find(
        (plan) => plan.id === plan_id
      );
      if (selectedPlanset) {
        setSelectedPlan(selectedPlanset);
      }
    }
  }, [dataPlans, plan_id]);

  const aftersignup = (signup_data: UserItem) => {
    signup_data.is_paying = true;
    dataContext.setUser(signup_data);
    setSection("payment");
  };

  const renderSection = () => {
    switch (section) {
      case "payment":
        return (
          <PaymentSection
            selectedPlan={selectedPlan!}
            paypal_setting={
              dataPlans && dataPlans.pageSettings
                ? dataPlans.pageSettings[0]
                : undefined
            }
          />
        );

      default:
        return (
          <SignupForm
            button_txt={
              pageType === "payment" ? "Continue to Payment" : undefined
            }
            promocode={pageType === "giveaway" ? "GIVEAWAY2024" : undefined}
            nextAction={pageType === "payment" ? aftersignup : undefined}
          />
        );
    }
  };

  return (
    <div className="container first-container px-md-0 mt-0 pt-4 signup_page">
      <h1 className="text-center w-75 mx-auto mb-3">
        Reading in Your World Language Class <br />
        Should Be Easy and Fun for You Too
      </h1>
      <hr />
      <div className="row justify-content-between">
        {pageType === "giveaway" ? <GiveawaySection /> : null}
        <div className="col-lg-5">
          <h4 className="black_text">{title}</h4>
          {renderSection()}
          <p className="black_text text-center mt-2">{sub_title}</p>

          {pageType === "payment" && section === "signup" ? (
            <small className="text-muted d-block text-center">
              Payment details on the next step
            </small>
          ) : null}
          <br />
          <br />
          {section === "signup" ? (
            <p>
              Already have an account?{" "}
              <Link to="/signin" className="text-primary">
                Sign In
              </Link>{" "}
            </p>
          ) : null}
        </div>
        {pageType === "payment" ? (
          <div className="col-lg-5">
            {dataPlans?.plans ? (
              <OrderSummary selectedPlan={selectedPlan} />
            ) : (
              <LoaderDots size={20} />
            )}
          </div>
        ) : pageType === "giveaway" ? null : (
          <BookInfo book={dataBooks?.book} loading={gettingBook} />
        )}
      </div>
    </div>
  );
};

const GiveawaySection = () => (
  <div className="col-lg-7">
    <div className="video_item hover">
      <video
        controls={true}
        autoCorrect="true"
        // onPause={handlePlayVideo}
        // ref="https://tupuedes.b-cdn.net/flg/testimonial_martha_flangoo.mp4"
        controlsList="nodownload"
      >
        <source
          src="https://tupuedes.b-cdn.net/flg/FGPromo01.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
);

type BookInfoProps = {
  book?: ReaderItem;
  loading: boolean;
};
const BookInfo: React.FC<BookInfoProps> = ({ book, loading }) => (
  <div className="col-lg-6">
    <div className="row">
      <div className="col-md-6">
        {loading ? null : book ? (
          <img src={book.images.cover} alt="" className="img-fluid" />
        ) : (
          <img
            src="https://flangoo-cdn.s3.us-east-2.amazonaws.com/2021-08-03_white.gif"
            className="img-fluid"
            alt="books animation"
          />
        )}
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <ul>
          <li>Give your students the confidence to use a new language</li>
          <li>
            Connect them with the culture you fell in love with through an
            online library of over 200 fully-narrated ereaders
          </li>
          <li>
            Enjoy teaching your students without spending all day writing a
            lesson plan
          </li>
        </ul>
      </div>
    </div>
    <hr />
    <p>
      <i>
        "They aren't just picking up grammar and vocabulary [with Flangoo], it's
        a love of reading and culture."
      </i>
    </p>
    <p className="text-right"> —Teacher Dagnah T.</p>
  </div>
);

export default TeacherSignup;
