import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

type VideoContainerProps = {
  video_url: string;
  image: string;
};
const VideoContainer: React.FC<VideoContainerProps> = ({
  video_url,
  image,
}) => {
  const videoJsOptions = {
    controls: true,
    autoplay: false,
    preload: "auto",
    sources: [
      {
        src: video_url,
        type: "video/mp4",
      },
    ],
  };
  return (
    <div className="video-container theater-mode">
      <VideoPlayer options={videoJsOptions} poster={image} />
    </div>
  );
};

type VideoPlayerProps = {
  options: any;
  poster: string;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({ options, poster }) => {
  const videoNode = useRef(null);
  const player = useRef<any>(null);
  const initialized = useRef(false);

  useEffect(() => {
    if (videoNode.current && !initialized.current) {
      initialized.current = true; // Prevent duplicate initialization
      player.current = videojs(videoNode.current, {
        ...options,
      });
    }

    // Clean up player on dismount
    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="video-cont">
      <video
        ref={videoNode}
        className="video-js"
        controlsList="nodownload"
        poster={poster}
      />
    </div>
  );
};

export default VideoContainer;
